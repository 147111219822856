import { createHashRouter, createRoutesFromElements, Route } from 'react-router-dom';
import App from '../features/app/App';
import { ErrorBoundary } from '../features/app/ErrorBoundary';
import MaintenancePage from '../features/history/MaintenancePage';

export const router = createHashRouter(
    createRoutesFromElements(
        <Route path="/" element={<App />}>
            <Route path="/:assetId" errorElement={<ErrorBoundary />} element={<MaintenancePage />} />
        </Route>,
    ),
);

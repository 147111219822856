import { createSlice } from '@reduxjs/toolkit';

interface AppState {
    sessionExpiredAcknowledged: boolean;
}

const initialState: AppState = {
    sessionExpiredAcknowledged: false,
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        hideSessionExpiredDialog: (state) => {
            state.sessionExpiredAcknowledged = true;
        },
    },
});

export default appSlice.reducer;

import ErrorState from '@rio-cloud/rio-uikit/lib/es/ErrorState';
import { FormattedMessage } from 'react-intl';

export type InternalErrorStateProps = {
    headlineI18n: string;
    messageI18n: string;
    additionalClassName?: string;
    innerClassName?: string;
    fullWidth?: boolean;
};

const InternalErrorState = ({
    headlineI18n,
    messageI18n,
    additionalClassName,
    innerClassName,
    fullWidth,
}: InternalErrorStateProps) => (
    <ErrorState
        icon={''}
        headline={<FormattedMessage id={headlineI18n} />}
        message={<FormattedMessage id={messageI18n} values={{ linebreak: <br /> }} />}
        // className should keep the original values plus the news values passed
        outerClassName={`${additionalClassName}`}
        innerClassName={innerClassName}
        fullWidth={fullWidth}
    />
);

export default InternalErrorState;
